<template>
  <div id="app">
    <el-row>
      <el-col :span="24">
        <el-page-header @back="goBack" content="公司设备展示" style="margin-top:10px"> </el-page-header>
      </el-col>
    </el-row>

    <!-- 公司结构 -->
    <div class="treeLeft">
      <div class="treea">
        <!-- <el-button type="primary" @click="getCompanyEq()">123</el-button> -->
        <el-input placeholder="公司查询" prefix-icon="el-icon-search" v-model="filterText">
        </el-input>

        <el-tree :check-strictly="true" :default-expanded-keys="[1]" :default-checked-keys="[1]" :data="allCompanyList" :props="defaultProps" default-expand-all :filter-node-method="filterNode" :expand-on-click-node="false" @node-click="checkCompany" ref="tree" style="height: 400px;overflow-y:scroll">
        </el-tree>
      </div>
      <div class="treeStu">
        <el-select v-model="companyEqTypes.name" placeholder="请选择查看的设备">
          <el-option v-for="item in companyEqTypes" :key="item.val" :value="item.name" :label="item.name">
          </el-option>
        </el-select>
        <el-tree :check-strictly="true" :data="companyType" :props="defaultPropsStu" default-expand-all :filter-node-method="filterNode" :expand-on-click-node="false" @node-click="checkType" style="height: 400px;overflow-y:scroll">
        </el-tree>
      </div>
    </div>

    <div class="view">
      {{companyEqTypes.name}}
    </div>

  </div>
</template>
<script>
// 公司系统
import companySystem from "@/api/managementApi/CompanyInfo";
import deviceInfo from "@/api/manageApi/DeviceInfoManagement";

export default {
  data() {
    return {
      mess: '',
      filterText: "",
      allCompanyList: [], // 公司集合
      companyType: [], // 类型集合
      companyEqTypes: [],  //  单个类型的设备集合 eg: 烟感 烟感 烟感
      // 公司树
      defaultProps: {
        label: "name", // 公司名 对应的字段
      },
      // 类型树
      defaultPropsStu: {
        label: "name", // 类型名

      },
      // 传后端的对象
      deviceInfoQuery: {
        id: '',
        typeName: '',
        typeId: ''
      },
    };
  },

  created() {
    this.getCompanyEq();
  },

  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },

  methods: {
    // 查询所有公司结构
    getCompanyEq() {
      companySystem.getCompanyEq().then((res) => {
        for (let i = 0; i < res.data.data.list.length; i++) {
          let str = res.data.data.list[i].name
          str = str.replace(/>/g, '')
          res.data.data.list[i].name = str
        }
        this.allCompanyList = res.data.data.list;

      });
    },
    // 点击公司树 获取数据
    checkCompany(checked) {
      this.deviceInfoQuery.id = checked.id
      this.deviceInfoQuery.typeName = checked.name
      // 拿公司获取公司下类型
      deviceInfo.getCompanyEqType(this.deviceInfoQuery.id).then((res) => {
        this.companyType = res.data.data.list
        this.companyEqTypes = [];
        // console.log(res);
      })
      // name传后端 查询类型
    },
    // 点击类型树
    checkType(checked) {
      this.deviceInfoQuery.typeId = checked.id;  //类型id
      deviceInfo.getCompanyEqTypes(this.deviceInfoQuery.id, this.deviceInfoQuery.typeId).then((res) => {
        // console.log(res);

        for (let i = 0; i < res.data.data.list.length; i++) {
          res.data.data.list[i].val = i;
          let a = i
          let b = ++a
          res.data.data.list[i].name = b + '号' + '\xa0' + res.data.data.list[i].name
        }
        this.companyEqTypes = res.data.data.list
        //  console.log(this.companyEqTypes);
      })

    },
    // 返回上一级
    goBack() {
      this.$router.push("/Select");
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

  },
};
</script>

<style lang="less" scope>
//树
.treeLeft /deep/ {
  height: 100%;
  margin-top: 15px;
  float: left;
  width: 25%;
  .el-input {
    margin: 10px 0;
  }
}
// 左边的
.treea /deep/ {
  float: left;
  width: 49%;
  height: 100%;
  margin: 0px 0 0 5px;

  // 树的样式
  .el-tree {
    border-top: dashed 1px rgb(99, 99, 99);
  }
  .el-tree-node__content {
    border-bottom: dashed 1px cornflowerblue;
    width: auto;
    border-radius: 5px;
    color: rgb(18, 35, 49);
    margin: 10px 0 0 0;
    height: 40px;
    padding-left: 15px;
  }
  .el-tree-node__label {
    position: relative;
    font-weight: bold;
    font-family: "宋体";
    font-size: 18px;
  }
  // 鼠标移动
  .el-tree-node__content:hover {
    color: #37a6da;
  }
  //  选中的样式
  .el-tree-node.is-current > .el-tree-node__content {
    // background: #c8efff;
    color: #37a6da;
    animation: moving 2s linear infinite;
  }
  @keyframes moving {
    0% {
      left: 0%;
    }
    100% {
      left: 50%;
    }
  }
}
// 树 块形样式
.treeStu /deep/ {
  width: 49%;
  float: right;
  padding: 0;
  .el-tree {
    border-top: solid 1px rgb(99, 99, 99);
  }

  // 每一块的样式
  .el-tree-node__content {
    width: auto;
    border-radius: 5px;
    color: rgb(18, 35, 49);
    margin: 8px 0 0 0;
    height: 33px;
  }
  .el-tree-node__label {
    font-weight: bold;
    font-family: "宋体";
    font-size: 18px;
  }
  // 鼠标移动
  .el-tree-node__content:hover {
    color: #37a6da;
  }
  //  选中的样式
  .el-tree-node.is-current > .el-tree-node__content {
    color: #37a6da;
  }
}

// 视图样式
.view {
  margin: 30px 5px;
  float: right;
  width: 73%;
  height: 100%;
}
</style>